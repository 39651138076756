import React from 'react';
import Layout from "./layout";
import WelcomePage from "../pages/Welcomepage";
import AFlows from "../pages/AFlows";
import AFlowsEdit from "../pages/AFlowsEdit"
//import WizPractice from "../pages/WizPractice"
import WizPracticeFun from "../pages/WizPractice"
//import Templates from "../pages/Templates";
import TemplatesFun from "../pages/Templates";
import Documents from "../pages/Documents";
import ASubmissions from "../pages/ASubmissions";
// import Profile from "../pages/profile";
import ProfileFun from "../pages/profile";
import Login from "../pages/login";
//import WizPracticeData from "../pages/WizPracticeData";
import PracticeWizForm from "../pages/WizPracticeData";
// import WizPracticeLogo from  "../pages/WizPracticeLogo";
import WizPracticeLogoFun from  "../pages/WizPracticeLogo";
// import WizAppOptions from "../pages/WizAppOptions";
import WizAppOptionsFun from "../pages/WizAppOptions";
// import WizAnamnesisPin from "../pages/WizAnamnesisPin";
import WizAnamnesisPinFun from "../pages/WizAnamnesisPin";
// import external__practice__anamnesis_pin_card from "../pages/anamnesis_pin_card";
import External__practice__anamnesis_pin_cardFun from "../pages/anamnesis_pin_card";
// import external__practice__logo_card from "../pages/practice__logo_card";
import External__practice__logo_cardFun from "../pages/practice__logo_card";
// import external__practice__options_card from "../pages/practice__options_card";
import External__practice__options_cardFun from "../pages/practice__options_card";
// import ExternalePractice from "../pages/external_practice";
import ExternalePracticeFun from "../pages/external_practice";
//import External_practice_edit from "../pages/external_practice_edit";
import External_practice_editFun from "../pages/external_practice_edit";
import anamnesis_at_home_flow_new from "../pages/anamnesis_at_home_flows_New";
import ASubmissionsDetail from "../pages/ASubmissionsDetail";
//import CreateTemplate from "../pages/CreateTemplate";
import CreateTemplateFun from "../pages/CreateTemplate";
import LocalServerError from "../pages/LocalServerError";
import MainProfile from '../pages/MainProfile';
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

const Body = () =>{
    return(
        <Layout>
            <Switch>
                 <Route exact path="/">
                 <Redirect to="/login" />
                 {/* <Redirect to="/external/welcome" /> */}
                </Route>
                <Route exact path="/login" component={Login}>
                </Route>
                <Route exact path="/external/welcome" component={WelcomePage}>
                </Route>
                <Route exact path="/LocalServerError" component={LocalServerError}>
                </Route>
                <Route exact path="/external/anamnesis_at_home_flows" component={AFlows}>
                </Route>
                <Route exact path="/external/anamnesis_at_home_flows/new" component={anamnesis_at_home_flow_new}>
                </Route>
                <Route exact path="/external/anamnesis_at_home_flows/:id/edit" component={AFlowsEdit}>
                </Route>
                {/* <Route exact path="/external/document_templates" component={Templates}> */}
                <Route exact path="/external/document_templates" component={TemplatesFun}>
                </Route>
                {/* <Route exact path="/external/welcome_wizard/practice" component={WizPractice} > */}
                <Route exact path="/external/welcome_wizard/practice" component={WizPracticeFun} >
                </Route>
                {/* <Route exact path="/external/welcome_wizard/practice_data" component={WizPracticeData} > */}
                <Route exact path="/external/welcome_wizard/practice_data" component={PracticeWizForm} >
                </Route>
                <Route exact path="/external/welcome_wizard/practice_logo" component={WizPracticeLogoFun} >
                </Route>
                {/* <Route exact path="/external/welcome_wizard/app_options" component={WizAppOptions} > */}
                <Route exact path="/external/welcome_wizard/app_options" component={WizAppOptionsFun} >
                </Route>
                {/* <Route exact path="/external/welcome_wizard/anamnesis_pin" component={WizAnamnesisPin} > */}
                <Route exact path="/external/welcome_wizard/anamnesis_pin" component={WizAnamnesisPinFun} >
                </Route>
                <Route exact path="/external/documents" component={Documents}>
                </Route>
                <Route exact path="/external/anamnesis_at_home_submissions" component={ASubmissions}>
                </Route>
                <Route exact path="/external/anamnesis_at_home_submissions/:id" component={ASubmissionsDetail}>
                </Route>
                {/* <Route exact path="/external/profile" component={Profile}> */}
                <Route exact path="/external/profile" component={ProfileFun}>
                </Route>
                {/* <Route exact path="/external/practice/edit" component={External_practice_edit}> */}
                <Route exact path="/external/practice/edit" component={External_practice_editFun}>
                </Route>
                {/* <Route exact path="/external/practice"  component={ExternalePractice}> */}
                <Route exact path="/external/practice"  component={ExternalePracticeFun}>
                </Route>
                {/* <Route exact path="/external/practice/logo_card"  component={external__practice__logo_card}> */}
                <Route exact path="/external/practice/logo_card"  component={External__practice__logo_cardFun}>
                </Route>
                {/* <Route exact path="/external/practice/options_card"  component={external__practice__options_card}> */}
                <Route exact path="/external/practice/options_card"  component={External__practice__options_cardFun}>
                </Route>
                {/* <Route exact path="/external/practice/anamnesis_pin_card"  component={external__practice__anamnesis_pin_card}> */}
                <Route exact path="/external/practice/anamnesis_pin_card"  component={External__practice__anamnesis_pin_cardFun}>
                </Route>
                {/* <Route exact path="/external/document_templates/CreateTemplate/"  component={CreateTemplate}> */}
                <Route exact path="/external/document_templates/CreateTemplate/"  component={CreateTemplateFun}>
                </Route>
                {/* <Route exact path="/external/document_templates/CreateTemplate/:id"  component={CreateTemplate}> */}
                <Route exact path="/external/document_templates/new"  component={CreateTemplateFun}>
                </Route>
                <Route exact path="/external/profileList"  component={MainProfile}>
                </Route>
             </Switch>
          
        </Layout>
        
    )
}

export default Body;