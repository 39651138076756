import Typography from '@material-ui/core/Typography';




export default function LocalServerError()
{

    return(
        <div className="mb-3">
        <Typography variant="h4" className="mb-3" align="left" >
          Local Server Connection Issue.
        </Typography>
      </div>
    )
}