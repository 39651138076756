import React, { useState, useEffect } from 'react';

import Header from "./components/header";
import Footer from "./components/footer";
import Body from "./components/body";
import Container from '@material-ui/core/Container';

import './css/App.css';
import { useLocation } from "react-router-dom";
const marginfromtop = {
  marginTop: "25px"
}
function App() {
  let location = useLocation();
  return (
    <div className="App">
   {location?.pathname !== '/login'&&  <Header />}
    <Container style={marginfromtop} maxWidth="lg" >
      <Body />
     </Container>
     {location?.pathname !== '/login'&&<Footer />}
    </div>
  );
}

export default App;
