
// New Local Url's
// export const ServerUrl = 'https://localhost:44310'; //New structure cloud api url
// export const ServerUrlLocal = 'https://localhost:44358'; //New structure local api url
// export const ServerUrlAditor = 'http://localhost:8004/';
// export const ServerUrlHome = 'http://localhost:8001/';




//Client Server
export const ServerUrl = 'https://api.altona.app';
export const ServerUrlLocal = 'https://local.altona.app';
export const ServerUrlAditor = 'https://editor.altona.app/external/editor/';
export const ServerUrlHome = 'https://anamnese.altona.app/';




