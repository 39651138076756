import { React, useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import MUIDataTable from "../../MUI";
import MUIDataTable from "mui-datatables";
import { get_Profile, del_Profile } from "../api";
import Loader from "react-loader-spinner";
import Swal from 'sweetalert2'
import EditProfile from '../pages/editProfile';

function MainProfile() {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [selectId, setSelectId] = useState(null);
    const [recallList, setRecallList] = useState(false);
    useEffect(() => {
        setLoader(true);
        apiCalls();
    }, []);
    useEffect(() => {
        if(recallList === true){
            setLoader(true);
            setTimeout(() => {
                apiCalls();
                setRecallList(false);
            }, 100);
        }
    }, [recallList]);
    const apiCalls = async () => {
        let res = await get_Profile();
        setData(res);
        if (!!res && res.length > 0) {
            setLoader(false);
        }
    }
    const deleteProfile = async (e) => {
        setLoader(true);
        var resDel = await del_Profile(e.currentTarget.id);
        apiCalls();
        setRecallList(false);
    }
    const editProfile = async (e) => {
        setSelectId(e.currentTarget.id)
        setShow(true);
    }
    const options = {
        filterType: 'checkbox',
        selectableRows: false,
        sortOrder: {
            name: 'email',
            direction: 'asc'
        }
    };
    const columns = [
        { name: "salutation", label: "Salutation" },
        { name: "firstName", label: "First Name" },
        { name: "lastName", label: "Last Name" },
        { name: "email", label: "Email" },
        {
            label: "Actions",
            name: "id",
            options: {

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Button variant="contained" color="primary" id={value} onClick={(e) => editProfile(e)} style={{ borderRadius: "15px", marginRight: "5px" }}>
                                Edit
                            </Button>
                            <Button variant="contained" color="primary" style={{ borderRadius: "15px" }} id={value} onClick={(e) => deleteProfile(e)}>
                                Löschen
                            </Button>
                        </>

                    );
                }
            }
        }
    ];
    return (
        <>
            <div className="mb-3">
                <Loader
                    type="TailSpin"
                    color="#015270"
                    height={100}
                    width={100}
                    visible={loader}
                    className="ourloader"
                />
                <Typography variant="h4" className="mb-3" align="left" >
                    Profiles
                </Typography>

                <MUIDataTable
                    title={""}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
          <EditProfile show={show} setShow={setShow} selectId={selectId} setRecallList={setRecallList}/>
        </>
    );
}

export default MainProfile;