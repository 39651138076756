import { React, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { update_profile,getProfileByUserId } from '../api';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function EditProfile(props) {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        salutation: "",
        firstName: "",
        lastName: "",
        email: ""
    });
    useEffect(async() => {
        if (props.show === true) {
            let resdata = await getProfileByUserId(props.selectId);
            if(!!resdata){
                setTimeout(() => {
                    setFormData(resdata);
                    setOpen(true);
                }, 100);
            }
        }
    }, [props.show]);
    const handleClose = () => {
        setOpen(false);
        props.setShow(false);
        props.setRecallList(true);
    };
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }
    const handleSubmit = async (e) => {
        let res = await update_profile(formData);
        handleClose();
    }
    return (
        <div>

            <Dialog maxWidth={"md"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Edit Profile
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className="row">

                                    <div className="col-sm-6 mb-2">
                                        <TextField
                                            label="Anrede"
                                            id="outlined-basic"
                                            defaultValue={formData.salutation}
                                            onChange={handleChange}
                                            className="w-100"
                                            variant="outlined"
                                            name="Salutation"
                                            size="small"
                                        />
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <TextField className="w-100" name="firstName" onChange={handleChange} defaultValue={formData.firstName} size="small" label="Vorname *" id="outlined-basic" variant="outlined" />
                                    </div>
                                    <div className="col-sm-6 ">
                                        <TextField className="w-100" name="LastName" onChange={handleChange} defaultValue={formData.lastName} size="small" label="Nachname *" id="outlined-basic" variant="outlined" />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextField className="w-100" name="Email" onChange={handleChange} defaultValue={formData.email} size="small" label="Email *" id="outlined-basic" variant="outlined" />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleClose} style={{ borderRadius: "15px"}}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={(e) => handleSubmit(e)} style={{ borderRadius: "15px"}}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
