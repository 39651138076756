import React, { Component, useEffect, useState, useRef } from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styled from 'styled-components'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {loginApi} from '../api';
import { useHistory,useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';

const CenterForm = styled.div`
width:350px;
margin: auto;
height: 85vh;
display: flex;
align-items: center;
flex-direction:column;
justify-content:center`
const TextContainer = styled.div`
margin-bottom: 40px;`
const AthenaLoginText = styled.div`
font-size: 81px;
color:#015270;
text-transform: uppercase;
font-weight: 500;`
const AthenaLoginSemiText = styled.div`
font-size: 25px;
text-transform: uppercase;
font-weight: normal;
line-height: 1px;`

export default function Login() {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    let navigate = useHistory();
    let logBtn = useRef();

    const formik = useFormik({
        initialValues: {
            password: '',
            userName: ''
        },
        validationSchema: Yup.object().shape({
            userName: Yup.string().min(1, 'Too Short!').email("Invalid email address").required("Please enter email"),
            password: Yup.string().min(1, 'Too Short!').required("Please enter Password")
        }),
        onSubmit: async values => {
            let res = await loginApi(values);
            setCookie("token",res.token);
            setCookie("user",res.userId);
            setCookie("practice",res.practiceId);
            setCookie("ip",res.ip);
            navigate.push('/external/welcome');
        },
    });
    useEffect(() => {
        onEnter();
    }, []);
    const onEnter = () => {
        document.addEventListener("keydown", function (event) {
            if (event.keyCode === 13) {
                logBtn.current.click();
            }
        })
    }
    return (
        <>
            <CenterForm>
                <TextContainer>
                    <AthenaLoginText>
                        Athena
                    </AthenaLoginText>
                    <AthenaLoginSemiText>
                        Aufklarung & anamnese
                    </AthenaLoginSemiText>
                </TextContainer>
                <form className="form" >
                    <TextField
                        className="mb-3"
                        size="small"
                        id="userName"
                        type="text"
                        fullWidth={true}
                        label="Email"
                        variant="outlined"
                        error={formik.errors.userName && formik.touched.userName ? true : false}
                        helperText={formik.errors.userName && formik.touched.userName ? formik.errors.userName : ""}
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        id="password"
                        label="Password"
                        fullWidth={true}
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        className="mb-3"
                        size="small"
                        error={formik.errors.password && formik.touched.password ? true : false}
                        helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ""}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                    />
                    <Button variant="contained" fullWidth color="primary" className="form__custom-button"ref={logBtn} onClick={formik.handleSubmit}>
                        Anmelden
                    </Button>
                </form>
            </CenterForm>
        </>
    );
}