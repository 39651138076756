import React from "react";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SheetsTabs from "./SheetsTabs"
import Loader from "react-loader-spinner";
import { GetAllSheetsData_Template_SheetsTabs } from "../api";
import { useCookies } from 'react-cookie';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

export class Templates extends React.Component {
    constructor(props) {
        super(props);
        this.aufklärungsbögenRef = React.createRef();
        this.AnamnesebögenRef = React.createRef();
        this.state = {
            array: [],
            loader: false,
            practiceId: props.practice,
            profileId: props.user
        }

    }
    componentDidMount() {

        GetAllSheetsData_Template_SheetsTabs(this, this.props.practice, this.props.user);

    }
    executeScroll = (name) =>{
        if(name === "Aufklärungsbögen"){
            this.aufklärungsbögenRef.current.scrollIntoView({behavior: 'smooth'})
        }
        else{
            this.AnamnesebögenRef.current.scrollIntoView({behavior: 'smooth'})
        }
    }

    render(props) {
        return (
            <>
                <Loader
                    type="TailSpin"
                    color="#015270"
                    height={100}
                    width={100}
                    visible={this.state.loader}
                    className="ourloader"
                />
                <Card className="mb-3">
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="pb-0">
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Typography variant="h4" align="left" > Vorlagen </Typography>
                                    </Grid>
                                    <Grid item>
                                    <Button  variant="contained" color="primary" onClick={() => this.props.history.push("/external/document_templates/CreateTemplate")}>Neue Vorlage erstellen</Button>
                                        {/* <a variant="contained" className="btn" href="/external/document_templates/CreateTemplate" color="primary"> Neue Vorlage erstellen</a> */}
                                        {/* <a  variant="contained" onClick={(e)=>newrecord_Template_SheetsTabs(e)} color="primary"> Neue Vorlage erstellen</a> */}

                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="d-flex">
                                    <p className="mr-1 mb-0">
                                        Springe zu
                                    </p>
                                    <Link onClick={() => this.executeScroll("Aufklärungsbögen")}>
                                        Aufklärungsbögen
                                    </Link>
                                    <span className="mx-2">|</span>
                                    <Link onClick={() => this.executeScroll("Anamnesebögen")}>
                                        Anamnesebögen
                                    </Link>

                                </div>

                            </Grid>


                        </Grid>
                        <h4 className="mt-3" ref={this.aufklärungsbögenRef}>Aufklärungsbögen</h4>
                        <SheetsTabs title="Anamnesebögen" list1data={this.state.array.templateid1} list2data={this.state.array.templateid1} objState={this} />
                        <h4 ref={this.AnamnesebögenRef}>Anamnesebögen</h4>
                        <SheetsTabs title="Aufklärungsbögen" list1data={this.state.array.templateid2} list2data={this.state.array.templateid2} objState={this} />
                    </CardContent>
                </Card>
            </>
        )

    }
}

function TemplatesFun() {
    const [cookies, setCookie, removeCookie] = useCookies(['token', 'practice', 'user']);
    const history = useHistory();
    return ( 
        <>
        <Templates practice={cookies.practice} user={cookies.user} history={history}/>
        </>
     );
}

export default TemplatesFun;