import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getPracticeData_Practice } from '../api';
import { useCookies } from 'react-cookie';

export class ExternalePractice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AllowPriviousEntry: false,
            BlockingPassword: false,
            BugReports: false,
            BugReportTime: "1976-05-04T00:00:00.000Z",
            NavigateTo: "Detailpatients",
            Sendanalyticsdata: false,
            day: "",
            Month: "",
            Year: "",
            Hour: "",
            Minute: "",
            Logo: [],
            Name: "",
            Adress1: "",
            Adress2: "",
            City: "",
            Email: "",
            Phone: "",
            PostCode: "",
            Website: "",
            DangerZonePassword: "",
            dgsvo_data_protection_officer_address: '',
            dgsvo_data_protection_officer_contact: '',
            dgsvo_data_protection_officer_name: '',
            dgsvo_factoring_provide: '',
            Salutation: '',
            FirstName: '',
            LastName:'',
            practiceId: props.practice
        }
    }
    componentDidMount() {
        getPracticeData_Practice(this);
    }
    render() {
        return (
            <Card>
                <CardContent>
                    <div className="col-12">
                        <br />
                        <h2>{this.state.Name}</h2>
                        <br />
                        <address>
                            {this.state.Adress1}
                            <br />
                            {this.state.Adress2}
                            <br />
                            <br />
                            <i aria-hidden="true" className="fa fa-phone"></i>
                            {this.state.Phone}
                            <br />
                            <i aria-hidden="true" className="fa fa-envelope"></i>
                            <a href="mailto:info@dr-gallenbach.de">{this.state.Email}</a>
                            <br />
                            <i aria-hidden="true" className="fa fa-globe"></i>
                            <a href={this.state.Website} rel="noreferrer" target="_blank">{this.state.Website}</a>
                        </address>
                        Kundennummer: ""
                        <h4 className="mt-5">Technische Daten</h4>
                        Sperrpasswort: {this.state.DangerZonePassword}
                        <br />
                        <h4 className="mt-5 col-12">Optionen</h4>
                        <ul className="w-50 list-group mediaQueryXS">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Sperrpasswort für Aufklärungsbögen
                                <span className="text-muted">{this.state.BlockingPassword === true ? <i className="fa fa-check text-primary"></i> : <i className="fa fa-minus text-muted"></i>}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Zustimmung zum Senden von Fehlerberichten
                                <span className="text-muted">{this.state.BugReports === true ? <i className="fa fa-check text-primary"></i> : <i className="fa fa-minus text-muted"></i>}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Zustimmung zum Senden von Analysedaten
                                <span className="text-muted">{this.state.Sendanalyticsdata === true ? <i className="fa fa-check text-primary"></i> : <i className="fa fa-minus text-muted"></i>}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Laden von früheren Anamnese-Eingaben erlauben
                                <span className="text-muted">{this.state.AllowPriviousEntry === true ? <i className="fa fa-check text-primary"></i> : <i className="fa fa-minus text-muted"></i>}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Nach dem Speichern eines Anamnesebogens navigieren zurück zur
                                <span className="text-muted">
                                    {this.state.NavigateTo}
                                </span>
                            </li>
                        </ul>
                        <br />
                        <h4 className="mt-5">Zugehörige Nutzer</h4>
                        <ul className="w-50 list-group mediaQueryXS">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                {this.state.Salutation}  {this.state.FirstName}  {this.state.LastName}
                                <span className="text-muted">{this.state.Email}</span>
                            </li>
                        </ul>
                        <h4 className="mt-5">Daten für die DGSVO-Formulare</h4>
                        <br />
                        <h5>Datenschutzbeauftragter der Praxis</h5>
                        Name: {this.state.dgsvo_data_protection_officer_name}
                        <br />
                        Anschrift: {this.state.dgsvo_data_protection_officer_address}
                        <br />
                        Telefon / E-Mail: {this.state.dgsvo_data_protection_officer_contact}
                        <br />
                        <br />
                        <h5>Externes Abrechnungsunternehmen</h5>
                        {this.state.dgsvo_factoring_provide}
                        <br />
                        <div className="mt-3">
                            <a href="/external/practice/edit"><i aria-hidden="true" className="fa fa-pencil"></i>
                                Praxis bearbeiten
                            </a>|
                            <a href="/external/welcome">Zurück zur Übersicht</a>
                        </div>

                    </div>
                </CardContent>
            </Card>
        )
    }

}

function ExternalePracticeFun() {
    const [cookies, setCookie, removeCookie] = useCookies(['token', 'practice', 'user']);
    return (
        <>
            <ExternalePractice practice={cookies.practice} />
        </>
    );
}

export default ExternalePracticeFun;